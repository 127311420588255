<template>
  <div class="user" >
    <div class="header bg-color-red acea-row row-between-wrapper">
      <div class="picTxt acea-row row-between-wrapper">
        <div class="pictrue"><img :src="userInfo.avatar" /></div>
        <div class="text">
          <div class="acea-row row-middle" v-if="!userInfo.uid">
            <div class="name line1" style="font-size: 0.3rem;" @click="clicknavigateTo('/pages/login/index?url=https://www.twelwestars.com/Tasting/user')" >点击登录</div>
            
          </div>
          <div class="acea-row row-middle" v-else>
            <div class="name line1" >{{ userInfo.nickname }}</div>
          </div>
          <div v-if="userInfo.phone">
            <router-link :to="'/user/data'" class="id">
              ID：{{ userInfo.uid || 0}}
            </router-link>
            <router-link :to="'/user/data'" class="bianji">
              个人资料<div class="iconfont iconbianji"></div>
            </router-link>
          </div>
          <!-- <router-link :to="'/user/binding'" class="binding" v-else >
            <span>绑定手机号</span>
          </router-link> -->
         <div  class="user-type"  >
            <!-- <span class="member acea-row row-middle" >白羊座</span> -->
            <span v-if="userInfo.city" class="member acea-row row-middle" >{{userInfo.city}} </span>
         </div>
         <div class="user-type exp-bar">
            <div v-if="userInfo.rank_name" class="one">
              <img src="@assets/images/no1.png"  />
              </div>
            酒量级别排行: &nbsp;&nbsp;<span class="member acea-row row-middle" v-if="userInfo.rank_name" >{{userInfo.rank_name}}&nbsp;</span>
            <div style="float:right;margin-right:0.4rem;">
              <div style="display: inline;margin-right:0.1rem;" v-if="userInfo.rankCountry">全国：{{userInfo.rankCountry}}</div>
              <div style="display: inline;" v-if="userInfo.rankCity">本市：{{userInfo.rankCity}}</div>
            </div>
          </div>
        </div>
      </div>
      <span class="rand" @click="$router.push({ path: '/user/data' })">
          <div class="rand1-font-set font-style-bai"><div>酒量值PR：</div>{{userInfo.exppr}}</div>
          <div class="rand1-font-set font-style-bai"><div>购买值：</div>{{userInfo.exp}}</div>
          <div class="rand1-font-set font-style-bai"><div>经验积分：</div>{{userInfo.integral_total}}</div>
          <div class="rand1-font-set font-style-bai"><div>剩余积分：</div>{{userInfo.integral}}</div>
      </span>
      <div class="progress-box-po">
      <div class="process-box">
          <div :style="'width:' +(userInfo.pr)+'%'"></div>
      </div>
        <div class="progress-item font-style-bai">
            <span  v-for="(item,index) in userInfo.rank">{{item.name}}</span>
        </div>
      </div>
    </div>
    <div class="bottom-bg bg-top">
        <img src="@assets/images/userBgs.jpg"/>
    </div>
    <div class="black-title bg-top">
      <div class="black-title-box font-style-bai">
        <span class="myWineCellar">我的酒窖</span>
        <div class="myCount">
          <span>总收藏：<span>{{wcjCount.drink}}</span>瓶</span>
          <span>已经喝过：<span>{{userInfo.judge_count}}</span>瓶</span>
        </div>
      </div>
      <div class="red-shuju-box">
          <div class="myCount-detailed font-style-bai">
            <span>{{userInfo.integral_total}}<div>积分</div></span>
            <span  @click.prevent="onSelect('/Tasting/user/follow?active=c')">{{wcjCount.fans}}<div>粉丝</div></span>
            <span @click.prevent="onSelect('/Tasting/user/follow?active=b')">{{wcjCount.is_follow}}<div>关注</div></span>
            <span>{{wcjCount.judge_zan}}<div>获赞</div></span>
            <span>{{wcjCount.read}}<div>被阅读量</div></span>
            <span @click.prevent="onSelect('/Tasting/user/visitor')">{{wcjCount.visit}}<div>来访</div></span>
          </div>
          <div class="myOrder-box font-style-black bai-box">
            <div class="myOrder-above" style="padding:0.1rem">
              <!-- <img class="myOrder-flex" src="@assets/images/myOrder.png"/>
              <div class="myOrder-text myOrder-flex">
                <div class="myOrder-text-top">待评价订单<span class="not-order-box">（<span class="not-order-number font-style-red">9</span>订单尚未发表您的开瓶感受）</span></div>
                <div class="myOrder-text-bottom">分享评价，可获取20个积分，积分可可抵扣优惠<span>&nbsp;&nbsp;>>查看积分</span></div>
              </div> -->
              我的工具
            </div>
            <div class="line-bai"></div>
            <div class="myOrder-fun"  v-if="MyMenus.length">
              <span class="fun-len" style="    position: relative;" v-for="(item,index) in MyMenus" :key="index" >

                <Tag v-if="item.id==251&&mc" round color="#f00" style="position: absolute;z-index: 2;margin-top: 0rem;width: unset;top: -4px;">{{mc}}</Tag>
                <div v-if="item.id==259" class="myOrder-fun-icon">
                    <a href="/Tasting/saoma/index">
                      <img :src="item.pic" />
                    </a>
                </div>
                <div v-else class="myOrder-fun-icon" @click="goPages(index)"><img :src="item.pic" /></div>

                <div class="myOrder-fun-name">{{ item.name }}</div>
              </span>
              <!-- <span  name="fade" v-if="show"  class="fun-len">
                <div class="myOrder-fun-icon"><img src="@assets/images/dfk.png" /></div>
                <div class="myOrder-fun-name">待付款</div>
              </span> -->
              <!-- <span class="fun-len">
                <div class="myOrder-fun-icon"><img src="@assets/images/integral.png" /></div>
                <div class="myOrder-fun-name">积分</div>
              </span>
              <span class="fun-len">
                <div class="myOrder-fun-icon"><img src="@assets/images/LuckyMoney.png" /></div>
                <div class="myOrder-fun-name">红包</div>
              </span>
              <span class="fun-len">
                <div class="myOrder-fun-icon"><img src="@assets/images/coupon.png" /></div>
                <div class="myOrder-fun-name">优惠券</div>
              </span>
              <span class="fun-len">
                <div class="myOrder-fun-icon"><img src="@assets/images/fabu.png" /></div>
                <div class="myOrder-fun-name">我的发布</div>
              </span>
              <span class="fun-len">
                <div class="myOrder-fun-icon"><img src="@assets/images/set.png" /></div>
                <div class="myOrder-fun-name">设置</div>
              </span>
              <span name="fade" v-if="show" class="fun-len">
                <div class="myOrder-fun-icon"><img src="@assets/images/dfk.png" /></div>
                <div class="myOrder-fun-name">待付款</div>
              </span>
              <span name="fade" v-if="show" class="fun-len">
                <div class="myOrder-fun-icon"><img src="@assets/images/dfh.png" /></div>
                <div class="myOrder-fun-name">待发货</div>
              </span>
              <span name="fade" v-if="show" class="fun-len">
                <div class="myOrder-fun-icon"><img src="@assets/images/dsh.png" /></div>
                <div class="myOrder-fun-name">待收货</div>
              </span >
              <span name="fade" v-if="show" class="fun-len">
                <div class="myOrder-fun-icon"><img src="@assets/images/dpj.png" /></div>
                <div class="myOrder-fun-name">待评价</div>
              </span>
              <span name="fade" v-if="show" class="fun-len">
                <div class="myOrder-fun-icon"><img src="@assets/images/sh.png" /></div>
                <div class="myOrder-fun-name">售后</div>
              </span> -->
            </div>
            <!-- <div class="myOrder-fun" style="margin-top: 0.3rem;" v-else >正在努力开发中，敬请期待</div> -->
            <div class="zhanshi" v-on:click="showtool()" v-if="MyMenus.length >= 5" >
              <img  :src="show?require('@assets/images/shouqi.png'):require('@assets/images/zhanshi.png')"/>
            </div>
          </div>
          <!-- <div class="myArticle-box font-style-black bai-box">
            <ul>
              <li @click="cur=0" :class="{active:cur==0}">杯酒论人生2000</li>
              <li @click="cur=1" :class="{active:cur==1}">视频2000</li>
              <li @click="cur=2" :class="{active:cur==2}">想喝2000</li>
            </ul>
          </div>
          <div class="myArticle-box2 font-style-black ">
            <div v-show="cur==0">
              <ul class="option-one option-public">
                <li @click="optionfun(1,0)" :class="{actives:option==0}">全部</li>
                <li @click="optionfun(1,1)" :class="{actives:option==1}">酒宴2000</li>
                <li @click="optionfun(1,2)" :class="{actives:option==2}">聚会2000</li>
              </ul>
              <ul class="option-two option-public">
                <li @click="optionfun(2,0)" :class="{actives:options==0}">全部</li>
                <li @click="optionfun(2,1)" :class="{actives:options==1}">2020年</li>
                <li @click="optionfun(2,2)" :class="{actives:options==2}">2019年</li>
              </ul>
              <div class="myArticle-list" v-if="myArticleList" :v-for="(item, index) in myArticleList">
                  <span class="myArticle-box">
                  <div class="myArticle-img"><img class="Article-wid" src="@assets/images/assemble.jpg"/></div>
                    <div class="Article-text Article-wid" >定义进入过渡生效时的状态。</div>
                    <div class="Article-line Article-wid"></div>
                    <div class="Article-name Article-wid"><span class="Article-level"></span></div>
                    <div class="Article-oten Article-wid">
                        <span class="time">09-29</span>
                        <span class="city">广州</span>
                        <span class="iconfont iconxin"></span>
                        <span class="iconfont iconxiaoxi1"></span>
                        <span class="iconfont iconwode"></span>
                    </div>
                  </span>
                  <span class="myArticle-box">
                    <div class="myArticle-img"><img  class="Article-wid" src="@assets/images/brass.jpg"/></div>
                    <div class="Article-text Article-wid">定义进入过渡生效时的状态。</div>
                    <div class="Article-line Article-wid"></div>
                    <div class="Article-name Article-wid"><span class="Article-level"></span></div>
                    <div class="Article-oten Article-wid">
                        <span class="time">09-29</span>
                        <span class="city">广州</span>
                        <span class="iconfont iconxin"></span>
                        <span class="iconfont iconxiaoxi1"></span>
                        <span class="iconfont iconwode"></span>
                    </div>
                  </span>
              </div>
            </div>
          </div> -->
      </div>
    </div>
    
    <!-- <div class="wrapper">
      <div class="nav acea-row row-middle">
        <router-link :to="{ path: '/user/account' }" class="item">
          <div>我的余额</div>
          <div class="num">{{ userInfo.now_money || 0 }}</div>
        </router-link>
        <router-link
          :to="'/user/user_promotion'"
          class="item"
          v-if="userInfo.is_promoter === 1 || userInfo.statu === 2"
        >
          <div>当前佣金</div>
          <div class="num">{{ userInfo.brokerage_price || 0 }}</div>
        </router-link>
        <router-link :to="'/user/integral'" class="item" v-else>
          <div>当前积分</div>
          <div class="num">{{ userInfo.integral || 0 }}</div>
        </router-link>
        <router-link :to="'/user/user_coupon'" class="item">
          <div>优惠券</div>
          <div class="num">{{ userInfo.couponCount || 0 }}</div>
        </router-link>
      </div>
      <div class="myOrder">
        <div class="title acea-row row-between-wrapper">
          <div>我的订单</div>
          <router-link :to="'/order/list/'" class="allOrder">
            全部订单<span class="iconfont icon-jiantou"></span>
          </router-link>
        </div>
        <div class="orderState acea-row row-middle">
          <router-link :to="{ path: '/order/list/' + 0 }" class="item">
            <div class="pictrue">
              <img src="@assets/images/dfk.png" />
              <span
                class="order-status-num"
                v-if="orderStatusNum.unpaid_count > 0"
                >{{ orderStatusNum.unpaid_count }}</span
              >
            </div>
            <div>待付款</div>
          </router-link>
          <router-link :to="{ path: '/order/list/' + 1 }" class="item">
            <div class="pictrue">
              <img src="@assets/images/dfh.png" />
              <span
                class="order-status-num"
                v-if="orderStatusNum.unshipped_count > 0"
                >{{ orderStatusNum.unshipped_count }}</span
              >
            </div>
            <div>待发货</div>
          </router-link>
          <router-link :to="{ path: '/order/list/' + 2 }" class="item">
            <div class="pictrue">
              <img src="@assets/images/dsh.png" />
              <span
                class="order-status-num"
                v-if="orderStatusNum.received_count > 0"
                >{{ orderStatusNum.received_count }}</span
              >
            </div>
            <div>待收货</div>
          </router-link>
          <router-link :to="{ path: '/order/list/' + 3 }" class="item">
            <div class="pictrue">
              <img src="@assets/images/dpj.png" />
              <span
                class="order-status-num"
                v-if="orderStatusNum.evaluated_count > 0"
                >{{ orderStatusNum.evaluated_count }}</span
              >
            </div>
            <div>待评价</div>
          </router-link>
          <router-link :to="'/order/refund_list'" class="item">
            <div class="pictrue">
              <img src="@assets/images/sh.png" />
              <span
                class="order-status-num"
                v-if="orderStatusNum.refund_count > 0"
                >{{ orderStatusNum.refund_count }}</span
              >
            </div>
            <div>售后/退款</div>
          </router-link>
        </div>
      </div>
    </div> -->
    <!-- <img src="@assets/images/support.png" class="support" /> -->
    <!-- <div class="footer-line-height"></div> -->
    <!-- <SwitchWindow
      v-on:changeswitch="changeswitch"
      :switchActive="switchActive"
      :login_type="userInfo.login_type"
    ></SwitchWindow> -->
  </div>
</template>
<script>
import { getUser,getMenu } from "@api/tasting";
import { isWeixin } from "@utils";
import SwitchWindow from "@components/SwitchWindow";
import { Notify,Tag,Toast } from 'vant';
import wx from "weixin-js-sdk";

const NAME = "User";

export default {
  name: NAME,
  components: {
    SwitchWindow ,Notify,Tag,Toast
  },
  props: {},
  data: function() {
    return {
      userInfo: {},
      wcjCount:{},
      MyMenus: [],
      orderStatusNum: {},
      switchActive: false,
      isWeixin: false,
      show:true,
      isshow:false,
      cur:0,
      option:0,
      options:0,
      myArticleList:[],
      clientHeight: document.documentElement.clientHeight,
      setMenus:[],
      mc:0,
    };
  },
  watch: {
    $route(n) {
      if (n.name === NAME) this.User();
    }
  },
  created() {
            //this.id=this.$route.query.id;
            this.$store.state.app.token = this.$store.state.app.token?this.$store.state.app.token:this.$route.query.token;
            this.$route.query.storeid = this.store_id = this.$route.query.store_id;
  },
  mounted: function() {
    this.beforeCreate();
    this.User();
    this.MenuUser();
    this.isWeixin = isWeixin();
  },
  methods: {
    clicknavigateTo: function(url) {
      wx.miniProgram.navigateTo({url: url})
    },
    clickswitchTab: function(url) {
            wx.miniProgram.switchTab({url: url})
        },
    onSelect(item) {
      this.$router.push({ path: item });
    },
    beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color: rgb(234, 83, 62);')
    },
    optionfun:function(s,index){
      if(s==1){
          this.option=index;
      }else{
          this.options=index;
      }
      var op = this.option;
      var ops = this.options;
    },
    showtool:function(){
        let that = this;
        //if(that.MyMenus.length<=0)return;
        if (that.show) {
          //if (that.MyMenus.length <5) {
            let newArr = []
            for (var i = 0; i < 5; i++) {
              let item = that.setMenus[i]
              newArr.push(item)
            }
            that.MyMenus =  newArr
            that.show = false;
            //return that.show;
          //}
        }else{
          that.show = true;
          that.MyMenus = that.setMenus;
        }
    },
    changeswitch: function(data) {
      this.switchActive = data;
    },
    User: function() {
      //let mc = '0';
      let that = this;
      getUser().then(res => {
        that.userInfo = res.data;
        that.orderStatusNum = res.data.orderStatusNum;
        that.wcjCount = res.data.wcj_count;
        if(res.data.message_count){
          if(res.data.message_count>10){
              this.mc = '10+';
          }else{
              this.mc = res.data.message_count;
          }
            Notify({ type: 'warning', message: '您有'+this.mc+'条留言未读' });
        }
      });
    },
    MenuUser: function() {
      let that = this;
      getMenu().then(res => {
        that.MyMenus = res.data.wcj_my_menus;
        that.setMenus = res.data.wcj_my_menus;
      });
      
    },
    goPages: function(index) {
      let url = this.MyMenus[index].wap_url;
      if (url === "/user/user_promotion" && this.userInfo.statu === 1) {
        if (!this.userInfo.is_promoter)
          return this.$dialog.toast({ mes: "您还没有推广权限！！" });
      }
      if (url === "/customer/index" && !this.userInfo.adminid) {
        return this.$dialog.toast({ mes: "您还不是客服！！" });
      }
      if (url === "/pages/salesman/index") {
        this.clicknavigateTo(url);
        return;
      }
      this.$router.push({ path: this.MyMenus[index].wap_url });
    }
  }
};
</script>

<style scoped>
.user{overflow-x: hidden;position: absolute;z-index: 1;background-color: rgb(234, 83, 62);    width: 100%;min-height: 4.2rem;}
.line-bai{border-top:1px solid #dcdcdc;width: 100%;margin: 0.1rem 0;}
.font-style-bai{color: #fff; font-size: 0.2rem;}
.font-style-red{color:#c20201;}
.font-style-black{ color: #000;}
.zhanshi img{width: 5%;margin-top: 0.3rem;}
.iconfont{display: inline;font-size: 0.1rem;}
.footer-line-height {height: 1rem;}
.order-status-num {min-width: 0.33rem;background-color: #fff;color: #ee5a52;border-radius: 15px;position: absolute;right: -0.14rem;top: -0.15rem;font-size: 0.2rem;padding: 0 0.08rem;border: 1px solid #ee5a52;}
.picTxt{margin-bottom:1.2rem;}
.pictrue {position: relative;}
.switch-h5 {margin-left: 0.2rem;}
.binding {padding: 0.05rem 0.2rem;background-color: #ca1f10;border-radius: 50px;font-size: 0.14rem;border: 1px solid #e8695e;color: #ffffff;}
.bianji{padding-left:0.05rem;color: #fff;}
.user-type{display: block;color: #fff;margin-top: 0.2rem;}
.user-type span{position: relative;}
.user-type span::after{width: 0; height: 0;border-width: 0.08rem;border-style: solid;border-color: transparent #fff transparent transparent;transform: rotate(180deg);position:absolute;top: 0.13rem;right: 0.1rem;content: '';display: block;}
.rand{padding: 0.1rem;background:rgba(255,255,255,0.1);border-radius: 0.2rem;border:0.01rem solid rgba(255, 255, 255,0.2);margin-left: unset;position: absolute;right: 0.2rem;top:0.8rem;}
.rand1{height: 80%;margin:5%; font-size: 0.26rem; }
.rand1-font-set div{min-width: 1.2rem;display: inline-block;text-align: right;}
.rand1-font-set{margin-bottom: 0.02rem;}
.exp-bar{ position:absolute;top: 2.5rem;left: 0.2rem;width: 100%;}
.exp-bar .one{position: absolute;z-index: 2;left: 1.8rem;top:-0.1rem;}
.exp-bar .one img{width: 50%;height: 50%;}
.progress-box-po{position:absolute;top: 3.2rem;width: 92%;}
.process-box{ width: 100%;height: 11px;margin: 5px auto;border: #fff 1px solid;background: #fff;} 
.process-box div{height: 100%;background: red;}
.progress-item{display: flex;}
.progress-item span{flex-direction:row;justify-content:space-evenly;text-align: center;border-right: 1px solid #fff;flex-grow:1}
.progress-item span:first-child{border-left: 1px solid #fff;}
.bg-top{position: relative;top: -25px;}
.bottom-bg{z-index: -1;margin-bottom: -100px;width: 100%;height: 4rem;}
.bottom-bg img{width: 100%;}
.black-title{ width: 100%;}
.black-title-box{text-align: center;font-size: 0.4rem;width: 90%;height: 1.8rem;  border-radius: 0.5rem 0.5rem 0 0;background: rgba(0,0,0,0.6);margin: 0 auto;border:1px solid rgba(255,255,255,0.6);min-width:6rem ;}
.black-title-box .myCount,.red-shuju-box .myCount-detailed{ display: flex;margin-top:0.1rem ;padding:0 0.3rem;}
.black-title-box .myCount span,.red-shuju-box .myCount-detailed span{flex-direction:row;justify-content:center;flex-grow:1;font-size: 0.32rem;}
.red-shuju-box{text-align: center;position: relative;width: 100%;padding:0.3rem 0;background: #ea533e;top:-32px;border-radius: 0.5rem 0.5rem 0 0 ;}
.red-shuju-box .myCount-detailed{margin-bottom: 0.5rem;width: 100%;}
.red-shuju-box span{font-weight: 900;}
.red-shuju-box div{font-weight: 200;}
.bai-box{width: 90%;margin: 0 auto; background: #fff;border-radius: 0.5rem;padding:0.3rem;margin-bottom: 0.5rem;min-width: 6rem;}
.myOrder-box .myOrder-above,.myArticle-box ul,.myArticle-list{display: flex;}
.myOrder-box .myOrder-above img{width: 16%;height: 100%;}
.myOrder-box .myOrder-above .myOrder-flex,.myArticle-box li,.myArticle-list .myArticle-box{flex-direction:row;justify-content:space-evenly;flex-grow:1;}
.myOrder-box .myOrder-above .myOrder-text .myOrder-text-top{font-size: 0.3rem;font-weight: 900;}
.myOrder-box .myOrder-above .myOrder-text .myOrder-text-top .not-order-box{font-size: 0.2rem;font-weight:200;}
.myOrder-box .myOrder-above .myOrder-text .myOrder-text-top .not-order-box .not-order-number{font-size: 0.3rem;font-weight: 900;}
.myOrder-box .myOrder-above .myOrder-text .myOrder-text-bottom{font-size: 0.18rem;justify-content:left;}
.myOrder-box .myOrder-fun,.myArticle-box2 .option-public{display: flex;flex-wrap: wrap;}
.myOrder-box .myOrder-fun span{flex-direction:row;justify-content:space-evenly;margin-top: 0.3rem;width: 20%;}
.myOrder-box .myOrder-fun .myOrder-fun-icon img{width: 31px;height: 31px;}
.myOrder-box .myOrder-fun .myOrder-fun-name{font-size: 0.2rem;}
.myOrder-box .myOrder-fun .myOrder-fun{height: 10%;}
.myArticle-box.bai-box{background-color:#f3f3f3;border-radius: 0.5rem 0.5rem 0 0 ;margin-bottom:unset ;}
.myArticle-box ul .active{border-right: 2px solid #000;}
.myArticle-box2 .actives{background-color: #f24b42;}
.myArticle-box2{padding:0.3rem;background: #fff;width: 90%;margin: 0 auto;border-radius:0 0 0.5rem 0.5rem;}
.myArticle-box2 .option-one{margin-bottom: 0.2rem;}
.myArticle-box2 .option-one li,.option-two li{
  flex-direction:row;justify-content:space-evenly;border:1px solid #e2e2e2;padding: 0 0.2rem;border-radius: 0.1rem;
  margin-right: 2%;
}
.option-two li{border-radius: 0.3rem;padding: 0.08rem 0.3rem;line-height: 20px;}
@keyframes identifier {
 from {transform: translateY(100%);}
 to {transform: translateY(0%);}
}
@keyframes against {
 from {transform: translateY(0%);}
 to {transform: translateY(60%);}
}
.fade-enter-active {
  animation: identifier .4s;
}
.fade-leave-active {
  animation: against .4s ;
}
.myArticle-list{margin-top: 20px;}
.myArticle-list .myArticle-box{width: 50%;border-radius: 1rem;}
.myArticle-list .myArticle-img{width: 100%;height: 3rem;display: flex;align-items: center}
.myArticle-list .myArticle-img img{align-items: center}
.myArticle-list .myArticle-box .Article-wid{width: 95%;height: auto;}
.myArticle-list .myArticle-box .Article-line{border: 1px dashed #333333;}
.myArticle-list .myArticle-box .Article-text{}
</style>
